///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
フォーム部品（ラベル、他）

フォームのラベルに関する要素を定義します。
*/

/*
タイトルラベル

リストレイアウトでのタイトル部分など、会員登録ページでいえば「お名前」の部分のスタイリングになります。

メモ：
フォーム周りのタイトルラベルという位置づけの印象が強いですが、EC-CUBE4では「当サイトについて」や「特商」のページでも使われており、
どちらかというと汎用的なタイトルラベルという使い方なのかもしれません。
*/
.ec-label {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  margin: 0;

  color: $clrDefaultDark;
  letter-spacing: 0.05em;

  @include media_sphone {
    font-weight: 500;
  }
}



/*
必須ラベル

上記のタイトルラベルに付随する「必須」項目を示す部位になります。
*/
.ec-required {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  font-weight: normal;
  line-height: 1;
  padding: 0.4em 0.4em;
  background-color: $clrRed;//赤文字を背景色に使用
  color: #fff;
  margin-left: 1em;
}





/*
その他の調整

コーディングルールに則っていない（？）部位の調整。<br>
ミスなのか意図的なのかは不明ですがこれまでのフォーム構成（ルール）とは逸れている箇所があり、ミスであるならば本来はTwigを修正すべきですが、
テンプレート的にはTwigの編集は必要以外は極力しないでおきたいですので（バージョンアップの事も考慮しつつ）CSS側で吸収を試みます。

* なるべくピンポイントに働くように、他の部位に影響・汚染の無いよう心掛ける。
* もしバージョンアップでTwigの修正が施された際に悪影響のないようにしておきたい。
*/



/*
ショッピングページ「お客様情報」の変更フォーム

「都道府県」入力欄がここではテキスト入力だが、これを括る要素が.ec-selectとなっておりCSSルールから外れてしまっているので補正
*/
.ec-select {

  .customer-address_pref {

    input {
      @include _form-control;    
    }
  }
}



/*
問い合わせフォーム

問い合わせフォームで、.ec-zipInputHelpが、.ec-zipInputの中ではなく外に出てしまっている
*/
#page_contact {

  .ec-zipInput {
    display: inline-block;
  }

  // .ec-zipInputに続く都道府県selectと住所Input間のマージン調整
  .ec-zipInputHelp {

    & + .ec-select {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}
