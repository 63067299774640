///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
動画スライダー

slickスライダーに動画を含める事のできる「jeia_movieslider.js」用のスタイリングです。

通常のslickスライダーのスタイリングに対して、動画スライダー用にいくつか上書きと追加スタイリングを施しています。
*/
.ec-headPromotionRole {
  //position: relative;

  // プロモーション展開エリア
  .promotionRect {
    position: relative;
    overflow: hidden;// slick完成までの間に横にスクロールバーが出てしまうのを防ぎます
    // 縦横サイズは「jeia_movieslider.js」実行時に確定されます。
  }

  // slickコンテナ
  .ec-slickContainer {

    &:not( .is-ready ) {
      opacity: 0;
    }
    &.is-ready {
      opacity: 1;
    }
  }

  // スライドitem
  .slick-slide {
    overflow: hidden;
    transition: filter 1s;
    pointer-events: none;// ドラッグスライドができるように

    // 切り替わり中のエフェクト
    &.is-change {
      //filter: blur(5px);// saturate(0.5);
    }

    .itemContainer {

      > a {
        display: block;
        pointer-events: auto;
      }

      // iframe要素
      iframe {
        border: none;
      }

      // video要素
      video {
        display: block;
      }

    }
  }

  // ローダー
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
    }
  }

  // SOUNDボタン
  .soundBtn {
    position: absolute;
    bottom: .5em;
    left: .5em;
    font-size: 25px;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.3));
    z-index: 10;

    visibility: hidden;
    opacity: 0;
    transition: 1s;

    &.is-ready {
      visibility: visible;
      opacity: 0.5;
    }

    &:hover {
      opacity: 1;
    }

    /* SOUND-ONボタン */
    &:before {
      font-family: "jeiafont";
      content: "\e926";
      display: none;
    }

    /* SOUND-OFFボタン */
    &:after {
      font-family: "jeiafont";
      content: "\e925";
      display: block;
    }

    /* SOUND出力中のアイコン切り替え */
    &.sound-on {
      &:before {
        display: block;
      }
      &:after {
        display: none;
      }
    }
  }

  // 動画メディアキャプション
  .mediaCaption {
    font-family: $ff-en;
    font-weight: 400;
    font-size: 1rem;
    max-width: 350px;
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translate(0,-50%);
    z-index: 9;
    overflow: hidden;

    .inner {
      background-color: rgba(255, 255, 255, 0.75);
      color: #000;
      padding: 1.5em;
      opacity: 0;
    }

    .text_small {
      font-size: 0.9333em;
    }

    .text_big {
      font-size: 2em;
      line-height: 1.2;
      margin: .25em 0;
      opacity: 0;
    }

    .text_description {
      font-size: 1em;
    }

    .linkBtn {
      display: inline-block;
      font-size: 1em;
      margin-top: 1em;
      line-height: 1;
      padding: 1em 1.5em;
      background-color: #000;
      color: #fff;
      pointer-events: auto;
      transition: .2s;

      &:hover {
        text-decoration: none;
        background-color: #333;
      }
    }

    .closeBtn {
      display: none;
    }

    /* キャプション表示 */
    &.is-active {

      .inner {
        // キャプション出現はanimationを使用します（animationを使わないと初回表示時にキャプションがアニメしてくれない場合あり）
        animation: viewCaption .5s forwards 1s;
        @include media_sphone {
          animation: viewCaptionSp .5s forwards 1s;
        }
      }

      .text_big {
        animation: viewCaption .8s forwards 1s;
      }
    }
    @keyframes viewCaption {
      0% {
        opacity: 0;
        transform: translate3d(-100%,0,0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }
    @keyframes viewCaptionSp {
      0% {
        opacity: 0;
        transform: translate3d(0,100%,0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }

    /* スマホ用調整（.mediaCaption） */
    @include media_sphone {
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 100%;
      transform: translate(0,0);
      font-size: 0.9rem;
      transition: transform 0.5s;

      .text_big {
        font-size: 1.2em;
      }

      .linkBtn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
      }

      /* キャプション折り畳みボタン */
      .closeBtn {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        font-size: 18px;
        line-height: 1.5;
        width: 1.5em;
        height: 1.5em;
        text-align: center;
        color: #000;
        margin-left: -0.75em;
        pointer-events: auto;

        &:before {
          font-family: 'jeiafont';
          content: "\e902";
          display: block;
          width: 100%;
          height: 100%;
          background-color: transparent;
          transform: rotate(90deg);
          //transition: 0.5s;
        }
      }

      /* キャプション折り畳み状態 */
      &.is-close {
        transform: translate3d(0,100%,0);
        overflow: visible;

        .closeBtn {
          transform: translate3d(0,-100%,0);

          &:before {
            transform: rotate(-90deg);
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }
  }
}





