///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
メディア

_media.scss
*/



/*
メディアクエリ

* @mixin media_desktop

EC-CUBE4標準ではモバイルファースト（スマートフォン）でスタイリングしたのち適宜、デスクトップ用にメディアクエリで上書きする形でスタイリングしている様子ですが、
当テンプレートではタブレットも加えた3デバイスのスタイリングしています。

スタイリングの仕方はケースバイケースで、上書きや汚染が起きないようメディアクエリの内容を範囲を大きくとってがっつり分離してスタイリングする場合や、
一度、デスクトップ用（あるいはスマートフォン用）のスタイリングを施してから、適宜上書き用のメディアクエリを書く場合もあります。

メディアクエリに関しては、後からの変更・修正の際に「ごちゃごちゃしてどこをいじればいいのか判りづらい」コードにならないよう気を付けたいと思っています。
ですので上書きの多用は避けたいとしております、が、上書きスタイリングのほうがコードがすっきりするならそのようにします。ケースバイケースです。

* スマホ：320px～767px
* タブレット：768px～1279px
* PC：1280px以上

※デバイスごとのブレイクポイントは_variables.scssにて変数で設定。
*/
@mixin media_sphone() {
  @media only screen and ( max-width: ($tablet - 1) ) {
    @content;
  }
}

@mixin media_tablet() {
  @media only screen and ( min-width: $tablet ) and ( max-width: ($desktop - 1) ) {
    @content;
  }
}

@mixin media_desktop() {
  @media only screen and ( min-width: $desktop ) {
    @content;
  }
}



/*
コンテナ

コンテナブロックのmax-width、左右余白などブロックスタイルの共通定義。
各セクションの左右余白の統一感はコンテナによってもたらされます。

※ブラウザのフル幅で背景を敷いたデザインも可能とするため、`.ec-layoutRole__contents`側のmax-widthを解除し、こちらの「コンテナ」でmax-widthを定義。
コンテナを適用するセクションでmax-widthを変更したい場合は、そのコンテナで随時上書きします。

※コンテナの左右余白については、コンテナを「box-sizing:content-box;」としておいてから、希望のままの値でmax-widthを指定し、デバイスごとの左右余白を定義。

EC-CUBE4ではマイページ用に調整されたコンテナがありましたが、当テンプレートではマイページも汎用コンテナでスタイリングしています。

* @mixin container
*/
@mixin container() {
  //margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: $container-maxwidth;

  // スマホ
  @include media_sphone {
    padding-left: $padding-sphone;
    padding-right: $padding-sphone;
  }

  // タブレット
  @include media_tablet {
    padding-left: $padding-tablet;
    padding-right: $padding-tablet;
  }

  // デスクトップ
  @include media_desktop {
    /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
    padding-left: $padding-desktop;
    padding-right: $padding-desktop;

    /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */
    @at-root {
      .ec-layoutRole__mainWithColumn &,
      .ec-layoutRole__mainBetweenColumn & {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}



/*
コンテナの上下マージン

サイト内各コンテナの余白に統一感を持たせるために用意したmixinで、単純に上下余白を定義した内容となります。
コンテナのデザインレイアウトによっては上書き調整される事を想定しています。
*/
@mixin containerMargin {

  @include media_sphone {
    margin-top:    $padding-sphone;
    margin-bottom: $padding-sphone;
  }

  @include media_tablet {
    margin-top:    $padding-tablet;
    margin-bottom: $padding-tablet;
  }

  @include media_desktop {
    margin-top:    $padding-desktop * 2;
    margin-bottom: $padding-desktop * 2;
  }
}



/*
コンテナの上下マージンをpaddingで実装する場合

コンテナが背景色を持っている場合はこちらを使用します。
*/
@mixin containerPadding {

  @include media_sphone {
    padding-top:    $padding-sphone;
    padding-bottom: $padding-sphone;
  }

  @include media_tablet {
    padding-top:    $padding-tablet;
    padding-bottom: $padding-tablet;
  }

  @include media_desktop {
    padding-top:    $padding-desktop * 2;
    padding-bottom: $padding-desktop * 2;
  }
}
