///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";

/*
Sass変数

_variables.scss

各パーツの色やサイズを変数として定義しています。
*/

/*
ヘッダーロゴ
*/
$headerlogo-height: 80px;//ヘッダーロゴ画像の高さ


/*
デバイス別設定
*/
// メディアクエリブレイクポイント
$sphone:  320px;
$tablet:  768px;
$desktop: 1280px;

// ルートフォントサイズ
$sphone-font-size:  14px;
$tablet-font-size:  15px;
$desktop-font-size: 15px;

// ヘッダー高さ
$sphone-header-height:  45px;
$tablet-header-height:  50px;
$desktop-header-height: 120px;

// 余白（※左右余白用に設定してますがセクション間の上下余白にも使っています）
$padding-sphone:  6.25vw;//  ブラウザ幅320px時で余白20pxとし伸縮
$padding-tablet:  3.906vw;// ブラウザ幅768px時で余白30pxとし伸縮
$padding-desktop: 40px;//   余白を40px固定


// コンテナの最大幅
$container-maxwidth: 1280px;// カート、マイページ等のテキストメインのページ
$container-maxwidth2: 1480px;// TOP、商品一覧・詳細といったビジュアル的ページは少し広めにします。ヘッダー・フッターもこちらのサイズになります


/*
ボタン
*/
// 通常ボタン
$btn-default-bg:     #242930;
$btn-default-color:  #fff;
$btn-default-border: #444;

// プライマリボタン（未使用）
$btn-primary-bg:     #5CB1B1;
$btn-primary-color:  #fff;

// アクションボタン
$btn-action-bg:      #d41d17;
$btn-action-color:   #fff;

// カートインボタン
$btn-cartin-bg:      #000;
$btn-cartin-color:   #fff;

// お気に入りボタン
$btn-favorite-bg:    #ff5555;
$btn-favorite-color: #fff;

// キャンセルボタン（←本質はその他のグレー系アクションボタン。命名がよろしくない）
$btn-cancel-bg:      #333333;
$btn-cancel-color:   #fff;

// 無効状態
$btn-disabled-bg:    #999;

// ボタン角丸め
$btn-border-radius-base: 0px;

// ボタン高さ（単位：rem推奨）
$btn-height: 3.33rem;

// 数量・カートインが横にセットされた部位の高さ（各部位、ボタンがこのサイズに揃う）
$cartinRow-size: 3.33rem;



/*
文字色・背景色
*/
// 基本文字色
$clrDefault:       #444;

// 基本文字色より少し濃いめ
$clrDefaultDark:   #222;

// 基本文字色より少し薄め
$clrDefaultThin:   #666;

// 赤文字
$clrRed:           #e2251f;// カートや注文履歴で使用（エラーとは別の趣旨の赤文字）

// エラー
$clrError:         #ca0000;

// ボーダーカラー
$clrBorderColor:   #eee;

// アクティブカラー（カートプログレス、ページャ、slickドット等の配色）
$active-color:     #000000;//#619fbe;// アクティブ
$noactive-color:   #c8c8c8;//#555;//  非アクティブ



/*
ヘッダー・ドロワー
当テンプレートでは白系（ヘッダーは白背景、ドロワーはライトグレー）でデザインしていますがフラグにより黒系に切り替える事ができます
*/
// 黒系ヘッダー切り替えフラグ
$headerThemeDark:    false;// ヘッダー・ドロワーを黒系にする場合はここをtrueにします

// 白系ヘッダー（通常）
$clrHeaderBase:      #fff;// ヘッダー背景色
$clrHeaderText:      #444;// ヘッダー文字色
$clrHeaderMenuHoverBg: rgba(0,0,0,.07);// ヘッダーメニューhover時背景色
$clrHeaderBurger:    #666;// バーガーアイコン色

$clrDrawerBase:      #f4f4f4;// ドロワー背景色
$clrDrawerText:      #444;// ドロワー文字色
$clrDrawerIcon:      #666;// ドロワー内アイコン
$clrDrawerBorder:    rgba(0,0,0,0.4);//#aaa;// 各メニューの区切り線
$clrDrawerBorder2:   rgba(0,0,0,0.2);//#ccc;// 開閉メニューOPEN時の左線

@if $headerThemeDark {
  // 黒系ヘッダー（フラグON時）
  $clrHeaderBase:    #000;// ヘッダー背景色
  $clrHeaderText:    #fff;// ヘッダー文字色
  $clrHeaderMenuHoverBg: rgba(255, 255, 255, 0.2);// ヘッダーメニューhover時背景色
  $clrHeaderBurger:  #fff;// バーガーアイコン色

  $clrDrawerBase:    #000;// ドロワー背景色
  $clrDrawerText:    #fff;// ドロワー文字色
  $clrDrawerIcon:    #aaa;// ドロワー内アイコン
  $clrDrawerBorder:  rgba(255, 255, 255, 0.4);// 各メニューの区切り線
  $clrDrawerBorder2: rgba(255, 255, 255, 0.3);// 開閉メニューOPEN時の左線
}

// ヘッダーポップアップ・商品カテゴリメニュー
$clrHeaderPopupBg:      #fff;// ポップアップ背景色
$clrHeaderPopupText:    #000;// ポップアップ文字色
$clrHeaderPopupHoverBg: #f0f1f2;// ポップアップhover時背景色
$clrHeaderPopupBorder:  #eee;// ポップアップ区切り罫線



/*
フッター
*/
$clrFooterBase:    #000;// フッター背景色
$clrFooterText:    #aaa;// フッター文字色



/*
シェア・SNSカラー
*/
$clr-twitter:      #1B95E0;
$clr-facebook:     #3B5999;
$clr-googleplus:   #db4a39;
$clr-pinterest:    #cb2027;
$clr-instagram:    #f00075;
$clr-line:         #06c755;
$clr-pocket:       #EE4056;
$clr-hatena:       #00a4de;



/*
フォーム部品
*/
$input-color:              #333;// color #555555(=Bootstrap標準)
$input-bg:                 #fff;// background-color #fff(=Bootstrap標準)
$input-border:             #ccc;// border-color #ccc(=Bootstrap標準)
$input-border-radius:      0;// border-radius 4px(=Bootstrap標準)
$input-bg-disabled:        #eee;// disabled bg-color #eeeeee(=Bootstrap標準)
$input-color-placeholder:  #ddd;// placeholder 文字色
$input-border-focus:       #3c8dbc;// フォーカス時のbox-shadow color

$radio-checked-color:      #0049a3;// ラジオボタンUI チェック時カラー（checkbox兼用）

$input-error-bg:           #FDF1F0;// エラー時 background-color
$input-error-border:       #aa0b00;// エラー時 border-color


/*
カート
*/
// カート背景色
$cart-bgcolor: #f3f1eb;// カートテーブルヘッダー背景、数量変更ボタン等のカート予備アクション背景（さし色的なバランス）

// カートエラー背景色
$cart-error-bgcolor: #ff9999;
