///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/buttons";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/opacity";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";

// 不要な定義をカットしたり調整したいのでBootstrapからコピーして上書き
@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    //background-color: darken($background, 10%);
    //    border-color: darken($border, 25%);

    @include tab-focus;
}

  &:hover {
    text-decoration: none;
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);
  }

  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background-color: $btn-disabled-bg;
    border-color: $btn-disabled-bg;
    cursor: $cursor-disabled;
    
    &:hover,
    &:focus,
    &.focus {
      background-color: $btn-disabled-bg;
          border-color: $btn-disabled-bg;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
/*
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
*/



/*
ボタン

_btn.scss
*/



/*
ボタンひな形

* @mixin _btn($color, $background, $border)
*/
@mixin _btn($color, $background, $border){

  // フォーム部品と共有して使われる変数は分離したいのでここで上書き
  $font-size-base: 1rem;// ※変更非推奨
  // ※以下の変数はインラインボタンのみに影響。ブロックボタンは生成mixinの中でそれぞれプロパティが上書きされる
  $padding-base-vertical: 0.6em;
  $padding-base-horizontal: 1em;
  $line-height-base: 1;

  display: inline-block;
  margin-bottom: 0; // For input.btn
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);

  font-weight: normal;
  text-decoration: none;
  //outline: 0;
  transition: .3s;
  @include button-variant($color, $background, $border);
  @include user-select(none);

  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
}



/*
インラインボタン

* @mixin btn-default<br>
* @mixin btn-action<br>
* @mixin btn-cancel<br>
* @mixin btn-primary
*/
@mixin btn-default() {
  @include _btn($btn-default-color, $btn-default-bg, $btn-default-border);
  font-size: 0.9rem;
}

@mixin btn-action() {
  @include _btn($btn-action-color, $btn-action-bg, $btn-action-bg);

  &:hover {
    // 赤系のボタンなので彩度を上げる
    background-color: saturate($btn-action-bg, 15%);
    border-color: saturate($btn-action-bg, 15%);
  }
}

@mixin btn-cancel() {
  @include _btn($btn-cancel-color, $btn-cancel-bg, $btn-cancel-bg);
}

@mixin btn-primary() {
  @include _btn($btn-primary-color, $btn-primary-bg, $btn-primary-bg);
}



/*
ブロックボタン

* @mixin blockBtn-default<br>
* @mixin blockBtn-action<br>
* @mixin blockBtn-cancel<br>
* @mixin blockBtn-primary
*/
@mixin blockBtn-default() {
  @include _btn($btn-default-color, $btn-default-bg, $btn-default-border);
  display: block;
  width: 100%;
  height: $btn-height;
  line-height: $btn-height;
  padding-top: 0;
  padding-bottom: 0;
}

@mixin blockBtn-action() {
  @include _btn($btn-action-color, $btn-action-bg, $btn-action-bg);
  display: block;
  width: 100%;
  height: $btn-height;
  line-height: $btn-height;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    // 赤系のボタンなので彩度を上げる
    background-color: saturate($btn-action-bg, 15%);
    border-color: saturate($btn-action-bg, 15%);
  }
}

@mixin blockBtn-cancel() {
  @include _btn($btn-cancel-color, $btn-cancel-bg, $btn-cancel-bg);
  display: block;
  width: 100%;
  height: $btn-height;
  line-height: $btn-height;
  padding-top: 0;
  padding-bottom: 0;
}

@mixin blockBtn-primary() {
  @include _btn($btn-primary-color, $btn-primary-bg, $btn-primary-bg);
  display: block;
  width: 100%;
  height: $btn-height;
  line-height: $btn-height;
  padding-top: 0;
  padding-bottom: 0;
}



/*
User select

ボタンひな形（@mixin _btn()）から使用されてるmixinで、テキストを選択不可にしている様子。
ほかからの使用は無い様子。

* @mixin user-select
*/
@mixin user-select($select) {
  -webkit-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select; // IE10+
  user-select: $select;
}



/*
リンクボタン

使用箇所は無い様子

* @mixin linkBtn
*/
@mixin linkBtn {
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}
