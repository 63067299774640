///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ヘルプ

各ヘルプページに関する Project コンポーネントを定義します。
*/
.ec-helpRole {
  @include container;

  .ec-navlistRole {
    // .ec-navlistRoleはマイページ用のナビメニューコンポーネントで、_9.1.maypage.scssにて定義されています。
    // ヘルプページでもこれを流用させてもらう形で使用し、マージン等の調整を追加します。
    @include media_sphone {
      margin-bottom: $padding-sphone;
    }
    @include media_tablet {
      margin-bottom: $padding-tablet;
    }
    @include media_desktop {
      margin-bottom: $padding-desktop;
    }
  }

  /* section */
  section {
  }

  section + section {

    @include media_sphone {
      margin-top: $padding-sphone;
    }

    @include media_tablet {
      margin-top: 40px;
    }

    @include media_desktop {
      margin-top: 60px;
    }
  }

  /* h3タイトル */
  h3 {
    font-size: 1.066em;
    font-weight: normal;
    margin: 2em 0 1em;
    border-left: solid 5px darken($cart-bgcolor, 20%);
    border-bottom: solid 1px $clrBorderColor;
    padding: .5em .5em;
  }

  /* h4タイトル */
  h4 {
    font-size: 1em;
    font-weight: 500;
    margin: 1em 0;

    &:before {
      content: "【";
    }

    &:after {
      content: "】";
    }
  }

  /* 段落 */
  p {
    font-size: 1rem;
    line-height: 1.75;
  }

  /* 画像 */
  .fig {

    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  /* table */
  table {
    width: 100%;
    margin-bottom: 1em;
    $borderColor: #ccc;

    th {
      border: solid 1px $borderColor;
      padding: 1em;
    }

    td {
      border: solid 1px $borderColor;
      padding: 1em;

      /* 価格表示セル */
      &.priceCell {
        text-align: right;
        white-space: nowrap;
      }
    }
  }

  /* 枠囲み */
  .ec-frame {
    border: solid 1px #ccc;
    padding: 1em;
    margin: 1em 0;
    font-size: inherit;
    line-height: 1.75;
  }
}



/*
規約条文

規約条文の汎用スタイルです。
*/
.ec-provision {

  & &__heading {
    font-size: 1rem;
    margin-bottom: 2em;
  }

  dl {
    font-size: 1rem;
    margin-bottom: 2em;

    dt {
      //font-size: 1.1em;
      font-weight: 700;
      margin-bottom: 0.5em;
    }

    dd {
      font-size: 1em;
      font-weight: normal;
      margin: 0;
    }
  }
}
