///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
新着情報

トップページで使用されている新着情報ブロックのスタイルです。
*/
.ec-newsRole {
  @include media_sphone {
    margin-top: $padding-sphone;
  }

  @include media_tablet {
    margin-top: $padding-tablet * 2;
  }
  
  @include media_desktop {
    margin-top: $padding-desktop * 2;
  }

  /* ニュース一覧枠 */
  & &__news {
    margin-top: -1rem;// ネガティブマージン
  }

  /* ニュースアイテム*/
  & &__newsItem {
    display: flex;
    padding: 1rem 0;
    @include borderBottom;

    @include media_sphone {
      display: block;
    }
  }

  /* 左枠（日付） */
  & &__newsHeading {
    flex: 0 0 auto;
    min-width: 6em;
    margin-right: 1rem;

    time {
      display: block;
      font-family: $ff-en;
      font-size: .9rem;
      margin-top: .1rem;
    }

    @include media_sphone {
      width: 100%;
      margin: 0;
    }
  }
  
  /* 右枠（ニュースタイトル、本文）*/
  & &__newsColumn {
    flex: 1 1 auto;

    @include media_sphone {
      width: 100%;
      margin: 0;
    }

    /* OPEN時の見せ方 */
    transition: .2s;

    &.is-active {
      padding: 1em;
      background-color: #f8f8f8;
    }
  }

  /* ニュースタイトル */
  & &__newsTitle {
    font-size: 1rem;
    font-weight: 500;
    display: inline;
    vertical-align: bottom;
  }

  /* MOREボタン */
  & &__moreBtn {
    display: inline-block;

    font-family: $ff-en;
    font-size: 0.8rem;
    line-height: 1;
    padding: 0.2rem 0.3rem;
    border: solid 1px #666;
    background-color: #fff;
    margin-left: 1rem;
    cursor: pointer;
  }

  /* ニュース本文 */
  & &__newsDescription {
    margin-top: 1em;
    display: none;
  }
}
