///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
商品レビュープラグイン

プラグインディレクトリ「/app/Plugin/ProductReview4/Resource/template/default」下のTwigファイルから、
「review.twig」のみテンプレートディレクトリにコピーして内容をカスタマイズしています。
その他の、index.twig, confirm.twig, complete.twigはコピーせず、プラグインディレクトリにあるTwigファイルが参照される形のままとしています。
その上で「index.twig」は一部CSS上書きスタイリングをしています。
*/




/*
「/app/Plugin/ProductReview4/Resource/template/default/index.twig」内に記述されているインラインCSSを上書きして調整します
*/
#product_review_recommend_level {

  label {
    position: static !important;
    line-height: .75 !important;
  }
}



//#product_review_area,
.ec-productReviewRole {

  @include container;
  max-width: $container-maxwidth2;// 商品詳細ページのmax-widthと同じにします

  @include containerMargin;

  // head
  & &__heading {
    display: flex;
    justify-content: center;
    background-color: #EFEFEF;
    font-size: 1.066rem;
    padding: 1.25em;
    border-radius: 7px;
    position: relative;

    // タイトル
    .heading_title {
    }

    // 平均の星の数
    .heading_average {
      color: #FFA64D;
      margin-left: .5em;
    }

    // レビュー数
    .heading_total {
      margin-left: .5em;
    }

    // 開閉ボタン
    .toggle {
      font-size: 1.25rem;
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      position: absolute;
      top: calc(50% - 1em);
      right: .25rem;
      transform: rotate(-90deg);
      transition: .3s;

      &.is-close {
        transform: rotate(90deg);
      }
    }
  }

  // レビューコンテンツ枠
  & &__content {
  }

  // レビューアイテム
  & &__item {
    margin-top: 2rem;

    @include media_desktop {
      margin-top: $padding-desktop;
    }

    // 1～3段格納枠
    .item_1 {
      display: flex;
    }

    .item_2 {
      margin-top: .5rem;
      display: flex;
    }

    .item_3 {
      margin-top: .5rem;
    }

    // 投稿日
    .review_date {
      font-size: 1rem;
    }

    // 投稿者お名前
    .review_name {
      font-size: 1rem;
      margin-left: .5em;

      a {
        color: inherit;

        i {
          color: #999;
          margin-left: .25em;
        }
      }
    }

    // 星の数
    .review_level {
      font-size: 1.066rem;
      color: #FFA64D;
    }

    // レビュータイトル
    .review_title {
      font-size: 1.066rem;
      font-weight: 500;
      margin-left: .5em;
    }

    // レビューコメント
    .review_comment {
      font-size: 1rem;
    }
  }

  // レビュー無しコメント
  .no_review {
    font-size: 1rem;
    margin-top: 2rem;
    text-align: center;

    @include media_desktop {
      margin-top: $padding-desktop;
    }
  }

  // 投稿ボタン
  & &__actions {
    margin-top: 2rem;

    @include media_desktop {
      margin-top: $padding-desktop;
    }

    .ec-blockBtn {
      width: 250px;
      margin: 0 auto;

      @include media_sphone {
        width: 100%;
      }
    }
  }
}
