///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

@mixin row{
  display: block;
  margin: 0;

  @include media_desktop {
    display: flex;
    justify-content: center;
  }
}

@mixin makeSmColumn($columns){
  min-height: 1px;

  @include media_desktop {
    width: percentage($columns / 12);
  }
}

/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。
*/



/*
2分割グリッド

画面２分割のグリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。
*/
.ec-grid2 {
  @include row;

  & &__cell{
    @include makeSmColumn(6);
  }

  & &__cell2{
    @include makeSmColumn(12);
  }
}



/*
3分割グリッド

画面３分割のグリッドです。
*/
.ec-grid3 {
  @include row;

  & &__cell{
    @include makeSmColumn(4);
  }

  & &__cell2 {
    @include makeSmColumn(8);
  }

  & &__cell3 {
    @include makeSmColumn(12);
  }
}



/*
4分割グリッド

画面４分割のグリッドです。
*/
.ec-grid4 {
  @include row;

  & &__cell{
    @include makeSmColumn(3);
  }
}



/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。
*/
.ec-grid6 {
  @include row;

  & &__cell{
    @include makeSmColumn(2);
  }
  
  & &__cell2{
    @include makeSmColumn(4);
  }
  
  & &__cell3{
    @include makeSmColumn(6);
  }
}



/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の10グリッドです<br>

※当テンプレートでは、この.ec-off1Gridのスタイリングはオフにしております。
EC-CUBE標準で備わっている機能は殺したくない所ですが、この.ec-off1Gridはデザイン的に外しておきたく、本来であればTwig側で調整すべきですが、
テンプレート的にTwigの編集は必要以外、極力しない方向でいきたいと考えております。
*/
.ec-off1Grid {
  //@include row;

  //& &__cell{
  //  margin: 0;
  //  @include media_desktop {
  //    @include makeSmColumn(10);
  //    margin-left: percentage(1 / 12);
  //  }
  //}
}



/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の8グリッドです
*/
.ec-off2Grid {
  @include row;

  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(8);// width: 66.6666%;
      //margin-left: percentage(2 / 12);// margin-leftは不要
    }
  }
}



/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の6グリッドです
*/
.ec-off3Grid {
  @include row;

  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(6);
      //margin-left: percentage(3 / 12);// margin-leftは不要
    }
  }
}



/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の4グリッドです
*/
.ec-off4Grid {
  @include row;

  & &__cell {
    margin: 0;

    @include media_desktop {
      @include makeSmColumn(4);//width: 33.3333%
      //margin-left: percentage(4 / 12);// margin-leftは不要
    }
  }
}



/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。
*/



/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。
*/
.ec-grid--left {
  justify-content: flex-start;
}



/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。
*/
.ec-grid--right {
  justify-content: flex-end;
}



/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--centerを付与すると内包してるセルを中央寄せにすることができます。（※デフォルトで中央寄せなので不要かと思われる）
*/
.ec-grid--center {
  justify-content: center;
}



/*
グリッドのタブレット時の挙動を追加

offGridを使う場面とは、メインコンテンツ幅に対して少し幅の狭いブロックでレイアウトデザインを組みたい場面と言えると思います。
（EC-CUBE標準ではメインコンテンツ自体を少し幅を狭めるorr1Gridも多用されておりますが…）
offGridはデスクトップ向けのスタイリングですのでタブレットでは適用されずフル幅になってしまいます。
ここではタブレット時もoffGridのスタイリングが加味されるよう調整しています。
*/
@include media_tablet {
  .ec-off2Grid,
  .ec-off3Grid,
  .ec-off4Grid {
    display: block;
    margin: 0;

    & &__cell {
      width: 450px;
      margin: 0 auto;
    }
  }
}
