///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ピックアップカテゴリ

トップページで使用されているピックアップカテゴリブロックのスタイルです。

カテゴリごとに5点の商品表示をします。
1つ目の商品が大きく表示されるレイアウトになっています。

1つ目の大画像は通常は左配置ですが、「.ec-pickupCategoryRole__list」にclass「.rev」を併記することで大画像の配置を右配置に切り替えます。

スマホ時はレイアウトを解除してスライド表示（slick）に切り替えます。
*/
.ec-pickupCategoryRole {
  @include container;

  @include media_sphone {
    margin-top: $padding-sphone;
  }

  @include media_tablet {
    margin-top: $padding-tablet;
  }
  
  @include media_desktop {
    margin-top: $padding-desktop * 2;
  }

  & &__list {
    @include clearfix;

    .ec-productItemRole {
      float: left;
    }
    
    /* スマホ用 */
    @include media_sphone {

      // レイアウト上、商品情報枠を非表示にします
      .ec-productItemRole__content {
        display: none;
      }
    }

    /* タブレット時レイアウト */
    @include media_tablet {
      $margin: 10px;

      // レイアウト上、商品情報枠を非表示にします
      .ec-productItemRole__content {
        display: none;
      }

      .ec-productItemRole:nth-child(1) {
        width: calc((100% - #{$margin}) / 2);
      }

      .ec-productItemRole:nth-child(n+2) {
        width: calc((((100% - #{$margin}) / 2) - #{$margin}) / 2);
        margin-left: $margin;
      }

      .ec-productItemRole:nth-child(2),
      .ec-productItemRole:nth-child(3) {
        margin-bottom: $margin + 2px;// 5枚の上下面が揃うように余白を調整します。正方形の商品画像の場合は+0pxで揃うと思います。
      }

      /* 反転レイアウト */
      &.rev {
        .ec-productItemRole:nth-child(1) {
          float: right;
        }
        .ec-productItemRole:nth-child(n+2) {
          margin-left: 0;
          margin-right: $margin;
        }
      }      
    }

    /* デスクトップ時レイアウト */
    @include media_desktop {
      $margin: 20px;

      // 商品情報をマウスオーバーアクション表示にします
      .ec-productItemRole {
        @include productItemHoverAction;
      }

      .ec-productItemRole:nth-child(1) {
        width: calc((100% - #{$margin}) / 2);
      }

      .ec-productItemRole:nth-child(n+2) {
        width: calc((((100% - #{$margin}) / 2) - #{$margin}) / 2);
        margin-left: $margin;
      }

      .ec-productItemRole:nth-child(2),
      .ec-productItemRole:nth-child(3) {
        margin-bottom: $margin + 5px;// 5枚の上下面が揃うように余白を調整します。正方形の商品画像の場合は+0pxで揃うと思います。
      }

      /* 反転レイアウト */
      &.rev {
        .ec-productItemRole:nth-child(1) {
          float: right;
        }
        .ec-productItemRole:nth-child(n+2) {
          margin-left: 0;
          margin-right: $margin;
        }
      }
    }
  }
}
