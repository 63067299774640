///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
トピック

トップページで使用されているトピックブロックのスタイルです。

トピックとしてピックアップしたいカテゴリや商品等、4点を表示します。

画像間の余白は変更可能です。余白に合わせて各アイテム領域のサイズは調整されます。
スマホ時はレイアウトが変わります。
*/
.ec-topicRole {

  @include media_sphone {
    /* 周囲余白 */
    padding: $padding-sphone;
  }

  @include media_tablet {
    /* 周囲余白 */
    padding: $padding-tablet;
  }

  @include media_desktop {
    /* 上余白 */
    margin-top: 20px;
  }

  & &__list {
    padding: 0;
    @include clearfix;
    overflow: hidden;// IE11でclearfixがうまく効かないのでoverflow:hiddenを併記
  }

  & &__listItem {
    float: left;

    // 各listitemの縦横サイズは厳密にスタイリングする必要がありますので、height:0;としておきpadding-topで高さを指定します（後述）。内容要素はpositionにて位置決めさせます。
    height: 0;
    position: relative;

    a {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      overflow: hidden;

      // 余白の大きさによって各アイテム領域は調整されますが、その際に画像がフィットするようimg要素ではなく背景画像を敷く形で実装しています。
      .pic {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: .6s;
      }

      &:hover {
        text-decoration: none;

        @include media_desktop {
          .pic {
            transform: scale(1.1);
            filter: brightness(.8);
          }
        }
      }
    }
  }

  // スマホ時
  @include media_sphone {
    & &__listItem:nth-child(1) {
      width: 100%;
      padding-top: 90%;// 領域の高さ
      margin-bottom: $padding-sphone;
    }

    & &__listItem:nth-child(2),
    & &__listItem:nth-child(3) {
        width: 50%;
      padding-top: 85%;// 領域の高さ
    }

    & &__listItem:nth-child(4) {
      width: 100%;
      padding-top: 50%;// 領域の高さ
      margin-top: $padding-sphone;
    }    
  }

  // タブレット時
  @include media_tablet {
    // 余白は調整可能です（1~2pxの細い余白だと余白が綺麗に整わない事があります）
    $margin: 10px;// 

    & &__listItem:nth-child(1) {
      width: calc((100% - #{$margin}) / 2);
      padding-top: 54%;// 領域の高さ
      margin-right: $margin;
    }

    & &__listItem:nth-child(2) {
      width: calc((((100% - #{$margin}) / 2) - #{$margin}) / 2);
      padding-top: 27%;// 領域の高さ
    }

    & &__listItem:nth-child(3) {
      width: calc((((100% - #{$margin}) / 2) - #{$margin}) / 2);
      padding-top: 27%;// 領域の高さ
      margin-left: $margin;
    }

    & &__listItem:nth-child(4) {
      width: calc((100% - #{$margin}) / 2);
      padding-top: calc(27% - #{$margin});// 領域の高さ
      margin-top: $margin;
    }
  }

  // デスクトップ時は余白を大きめに変更（各listItemの領域サイズを余白に合わせて再定義）
  @include media_desktop {
    // 余白は調整可能です
    $margin: 20px;

    & &__listItem:nth-child(1) {
      width: calc((100% - #{$margin}) / 2);
      padding-top: 54%;// 領域の高さ
      margin-right: $margin;
    }

    & &__listItem:nth-child(2) {
      width: calc((((100% - #{$margin}) / 2) - #{$margin}) / 2);
      padding-top: 27%;// 領域の高さ
    }

    & &__listItem:nth-child(3) {
      width: calc((((100% - #{$margin}) / 2) - #{$margin}) / 2);
      padding-top: 27%;// 領域の高さ
      margin-left: $margin;
    }

    & &__listItem:nth-child(4) {
      width: calc((100% - #{$margin}) / 2);
      padding-top: calc(27% - #{$margin});// 領域の高さ
      margin-top: $margin;
    }
  }

  /* 画像の上に被さるトピックタイトル */
  & &__listItemTitle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,-2em);

    display: inline-block;
    background-color: #fff;
    color: #000;
    padding: .5em 2em;
    font-size: 1rem;
    white-space: nowrap;

    @include media_sphone {
      display: none;
    }

    @include media_tablet {
      font-size: .9333rem;
      transform: translate(-50%,-1em);      
    }
  }
}
