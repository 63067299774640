///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/forms";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";
@import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";

/*
フォーム

_form.scss
*/



/*
フォームリセット

* @mixin forms-reset
*/
@mixin forms-reset {
  input[type="search"] {
    @include box-sizing(border-box);
  }

  // Position radios and checkboxes better
  input[type="radio"],
  input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9; // IE8-9
    line-height: normal;
  }

  input[type="file"] {
    display: block;
  }

  // Make range inputs behave like textual form controls
  input[type="range"] {
    display: block;
    width: 100%;
  }

  // Make multiple select elements height not fixed
  select[multiple],
  select[size] {
    height: auto;
  }

  // Focus for file, radio, and checkbox
  input[type="file"]:focus,
  input[type="radio"]:focus,
  input[type="checkbox"]:focus {
    @include tab-focus;
  }
}



/*
フォームコントールひな形

* @mixin _form-control
*/

// focus mixinを調整したいのでBootstrapからコピー
@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  //$bg-rgba: rgba(red($color), green($color), blue($color), .015);

  &:focus {
    border-color: $color;
    outline: none;//0;
    //@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.05));
    //background-color: $bg-rgba;
  }
}

@mixin _form-control {

  // ボタン部品と共有して使われる変数は分離したいのでここで上書き
  $font-size-base: 1rem;// ※変更非推奨
  $input-height-base:        auto;//  height: 34px(=Bootstrap標準)
  $padding-base-vertical:    .5em;//  上下padding 6px(=Bootstrap標準)
  $padding-base-horizontal:  .75em;// 左右padding 12px(=Bootstrap標準)
  $line-height-base:         1.3;//   line-height 1.42857(=Bootstrap標準)  

  display: block;
  width: 100%;
  height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: 16px;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $input-border;
  border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
  -webkit-appearance: none;
  @include box-shadow(none);
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus;

  // Placeholder
  @include placeholder;

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    border: 0;
    background-color: transparent;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }

  // [converter] extracted textarea& to textarea.form-control

  @include media_desktop {
    font-size: $font-size-base;
  }
}
